import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid2 as Grid, MobileStepper, Paper, Stack, Step, StepLabel, Stepper, Typography, styled } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import * as identityVerificationService from '../../_services/documentverification.service';
import LottieError from '../LoadingAnimations/LottieError';
import LottieLoading from '../LoadingAnimations/LottieLoading';
import LottieSuccess from '../LoadingAnimations/LottieSuccess';
import DocumentCheckCamera from './DocumentCheckCamera';
import LottieCamera from '../LoadingAnimations/LottieCamera';
import { generalService } from '../../_services/general.services';

// Custom styled component for image container
const ImageContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
    width: '200px', // Fixed width for consistency, adjust as needed
    '& img': {
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(1),
        width: '100%',
        height: 'auto',
        objectFit: 'cover', // Changed to 'cover' for better image fitting
    },
}));

// Custom styled component for title
const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textAlign: 'center', // Center align the text
}));


function DocumentCheck(props) {

    const { serviceRequestData } = props;

    const [firstPhoto, setFirstPhoto] = React.useState(null);
    const [secondPhoto, setSecondPhoto] = React.useState(null);
    const [firstPhotoText, setFirstPhotoText] = React.useState('');
    const [secondPhotoText, setSecondPhotoText] = React.useState('');
    const [stopFlag, setStopFlag] = React.useState(false);
    const [imageData, setImageData] = React.useState(null);
    const [imageText, setImageText] = React.useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completedPictures, setCompletedPictures] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [idvUpdated, setIdvUpdated] = React.useState(null);
    const [idvResultMessage, setIdvResultMessage] = React.useState('');

    function handleDocumentCaptureSucceed() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 1) {
            setFirstPhoto(imageData);
            setFirstPhotoText(imageText);
            setStopFlag(true);
        }
        if (activeStep === 2) {
            setSecondPhoto(imageData);
            setSecondPhotoText(imageText);
            setCompletedPictures(true);
        }
        setImageData(null);
        setImageText(null);
    }

    function retry() {
        setCompletedPictures(false);
        setImageData(null);
        setImageText(null);
        setActiveStep(0);
        setFirstPhoto(null);
        setSecondPhoto(null);
        setFirstPhotoText('');
        setSecondPhotoText('');
        setStopFlag(false);
    }

    function base64ToBlob(base64, mimeType) {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }

    const loggedUser = useSelector((state) => state.authentication.user);
    async function storeImage() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let f1 = firstPhoto.replace('data:image/jpeg;base64,', '').trim();
        let f2 = secondPhoto.replace('data:image/jpeg;base64,', '').trim();

        const formData = new FormData();
        formData.append('frontImage', base64ToBlob(f1, 'image/jpeg')); // converted to blob
        formData.append('backImage', base64ToBlob(f2, 'image/jpeg')); // converted to blob
        formData.append('companyID', loggedUser.companyID);
        formData.append('userID', loggedUser.userMongoID);
        formData.append('mid', loggedUser.mid);

        try {
            const response = await identityVerificationService.updateDocument(formData, loggedUser.accessToken);
            console.log(response);
            setIdvUpdated('done');
            setIdvResultMessage(response.data.message);
            if (serviceRequestData instanceof Object) {
                await generalService.markRequestKeyCompleted(serviceRequestData, loggedUser.accessToken);
            }
        } catch (err) {
            // Error Storing handler.
            console.error('Error storing video', err);
            setIdvUpdated('failed');
            setIdvResultMessage(err.data.message);
        }
    }

    const steps = [
        {
            id: 0,
            label: "Instructions"
        },
        {
            id: 1,
            label: "Front Picture"
        },
        {
            id: 2,
            label: "Back Picture"
        },
        {
            id: 3,
            label: "Verify information"
        },  
        {
            id: 4,
            label: "Completed!"
        },
    ]

    return (
        <Stack 
            spacing={2} 
            sx={{ 
                height: "100%",
            }}
            alignItems={"center"}
        >
            <Paper
                square
                elevation={5}
                sx={{
                    display: { xs: "flex", md: "none"},
                    alignItems: 'center',
                    height: "5%",
                    width: "80%",
                }}
            >
                <Typography variant='subtitle2' textAlign={"center"} sx={{width: "100%"}}>
                    {steps.find((e) => e.id === activeStep).label}
                </Typography>
            </Paper>

            <Box
                sx={{
                    display: { xs: "block", md: "none"}
                }}
            >   
                <MobileStepper
                    variant="dots"
                    steps={5}
                    position="static"
                    activeStep={activeStep}
                    sx={{ maxWidth: "100%", flexGrow: 1 }}
                />
            </Box>
    
            <Box
                sx={{
                    width: "100%",
                    display: { xs: "none", md: "block"}
                }}
            >
                <Stepper activeStep={activeStep} alternativeLabel>
                    {
                        steps.map((step) => 
                            <Step>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        )
                    }
                </Stepper>

            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    height: '100%', // This ensures the Box takes the full height of the viewport
                }}
            >
                {activeStep === 0 && (
                    <Stack
                        spacing={1}
                        sx={{
                            height: "100%",
                        }}
                    >
                        <Typography variant="h4">
                            Welcome to The Document Verification
                        </Typography>
                        <Box
                            sx={{
                                width: '100%',
                            }}
                        >
                            <Typography variant="body1" pb={2}>
                                The Document Verification is a process you should completed to confirm your identity. This process has 5
                                steps. The first step is to carefully read these instructions:
                            </Typography>

                            <Typography variant="body1" pb={2} pl={4}>
                                On the next step you will be asked to allow access to your camera. Please, allow it.
                            </Typography>
                            <Typography variant="body1" pb={2} pl={4}>
                                A small area of what your camera captures is displayed on the screen. You will be asked to take a picture of
                                your Documento Personal de Identificación (DPI) of both faces, first the Front Face and second the Back
                                Face.
                            </Typography>
                            <Typography variant="body1" pb={2} pl={4}>
                                Make sure to fit your DPI within the visible area, that is readable and that no glare or shadows are
                                present. Once you take a picture, we process your image locally to detect issues. If any found,
                                you will be asked to retake the picture.
                            </Typography>
                            <Typography variant="body1" pb={2} pl={4}>
                                Once both faces are captured, you will be asked to accept the terms and conditions to continue. If you
                                decide to continue, we will save your images and proceed to validate the content.
                            </Typography>
                            <Typography variant="body1" pb={2} pl={4}>
                                If you have any issues, please contact support.
                            </Typography>

                            <Typography variant="body2">
                                <sup>1</sup> We guarantee you that all data is processed and stored locally until you decide to complete the
                                process.
                            </Typography>
                            <Typography variant="body2">
                                <sup>2</sup> We will save your ID independently of the result of the comparison.
                            </Typography>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Button variant="outlined" sx={{ width: '100%' }} onClick={() => setActiveStep(1)}>
                                Continue
                            </Button>
                        </Box>
                    </Stack>
                )}
                {activeStep != 0 && activeStep != 4 && (
                    <>
                        {completedPictures && !!firstPhoto && !!secondPhoto ? (
                            <Stack direction="column" justifyContent={'center'} alignItems={'center'}>
                                    <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={2} sx={{ flexWrap: 'wrap' }}>
                                        <ImageContainer component={Paper}>
                                            <Title variant="subtitle1">Front Picture</Title>
                                            <Box component={'img'} src={`data:image/jpeg;base64,${firstPhoto}`} />
                                        </ImageContainer>
                                        <ImageContainer component={Paper}>
                                            <Title variant="subtitle1">Back Picture</Title>
                                            <Box component={'img'} src={`data:image/jpeg;base64,${secondPhoto}`} />
                                        </ImageContainer>
                                    </Stack>
                                <FormControl>
                                    <FormControlLabel
                                        aria-required
                                        control={<Checkbox onChange={(event) => setTermsAccepted(event.target.checked)} />}
                                        label="Accept terms and conditions"
                                        sx={{ alignSelf: 'left' }}
                                    />
                                </FormControl>
                                <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                                    <Button variant="outlined" color="warning" onClick={retry}>
                                        Start Over
                                    </Button>
                                    <Button variant="outlined" disabled={!termsAccepted} onClick={storeImage}>
                                        Continue
                                    </Button>
                                </Stack>
                            </Stack>
                        ) : (
                            <Box>
                                <DocumentCheckCamera
                                    activeStep={activeStep}
                                    stopFlag={stopFlag}
                                    cancelMessage="Return to Login Form"
                                    continueMessage="Complete Login"
                                    cancelCallbackFunction={() => { }}
                                    continueCallbackFunction={handleDocumentCaptureSucceed}
                                    imageData={imageData}
                                    setImageData={setImageData}
                                    imageText={imageText}
                                    setImageText={setImageText}
                                    loading={loading}
                                    setLoading={setLoading}
                                    loadingChildComponent={
                                        <Stack
                                            direction={"column"}
                                            spacing={1}
                                            alignItems={"center"}
                                        >
                                            <Typography variant="body1" color="textSecondary" textAlign={'center'}>
                                                Please wait...
                                            </Typography>
                                            <LottieLoading height={200} width={200} />
                                        </Stack>
                                    }
                                    failedCameraAccessChildComponent={
                                        <Stack
                                            direction={"column"}
                                            spacing={1}
                                            alignItems={"center"}
                                        >
                                            <Typography variant="body1" color="textSecondary" textAlign={'center'}>
                                                Failed to access your Camera. Either you have not allowed this site to use your camera or the
                                                camera is not accessible.
                                            </Typography>
                                            <LottieError height={200} width={200} />
                                        </Stack>
                                    }
                                    noAvailableCameraChildComponent={
                                        <Stack
                                            direction={"column"}
                                            spacing={1}
                                            alignItems={"center"}
                                        >
                                            <Typography variant="body1" color="textSecondary" textAlign={'center'}>
                                                We have not detected any available cameras in this device. Please, review your browser and
                                                device settings to allow access to the camera.
                                            </Typography>
                                            <LottieError height={200} width={200} />
                                        </Stack>
                                    }
                                    gettingCameraPermissionChild={
                                        <Grid
                                            container
                                            display="flex"
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            spacing={2}
                                        >
                                            <Grid size={12}>
                                                <Typography variant="body1" color="textSecondary" textAlign={'center'}>
                                                    Please, allow access to your camera in your browser.
                                                </Typography>
                                            </Grid>
                                            <Grid display={"flex"} size={12} justifyContent={"center"}>
                                                <LottieCamera height={250} width={250} />
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Box>
                        )}
                    </>
                )}
                {activeStep === 4 && !idvUpdated && (
                    <Stack alignItems={'center'} justifyContent={'center'}>
                        <Typography variant="h5">We're saving your Identity and validating... Give us a moment!</Typography>
                        <LottieLoading height={200} width={200} />
                    </Stack>
                )}
                {activeStep === 4 && idvUpdated === 'done' && (
                    <Stack alignItems={'center'} justifyContent={'center'}>
                        <Typography variant="h5">Successfully saved your Identity!</Typography>
                        <Typography variant="body1">{idvResultMessage}</Typography>
                        <LottieSuccess height={200} width={200} />
                    </Stack>
                )}
                {activeStep === 4 && idvUpdated === 'failed' && (
                    <Stack alignItems={'center'} justifyContent={'center'}>
                        <Typography variant="body1">{idvResultMessage}</Typography>
                        <LottieError height={200} width={200} />
                    </Stack>
                )}
            </Box>

        </Stack>
    );
}

export default DocumentCheck;
