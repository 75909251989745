import { config } from '../_helpers/apiConfig';
import { handleResponse, authHeader } from './services.index';

async function validate(emailAddress) {
    const requestOptions = {
        method: 'GET',
    };
    return fetch(`${config.apiUrl}/database/document-verification/validate/email/${emailAddress}`, requestOptions).then(handleResponse);
}

async function updateDocument(formData, token) {
    const options = {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ` + token,
        },
        body: formData,
    };
    return fetch(`${config.apiUrl}/database/document-verification`, options).then((res) => handleResponse(res));
}

async function detectIDInImage(contentBase64) {
    console.log(contentBase64)
    const options = {
        method: 'POST',
        headers: {
            ...authHeader()
        },
        body: JSON.stringify({
            contentBase64
        }),
    };
    return fetch(`${config.apiUrl}/database/document-verification/id-in-image`, options).then((res) => handleResponse(res));
}

export { validate, updateDocument, detectIDInImage };
