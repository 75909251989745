import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import LensTwoToneIcon from '@mui/icons-material/LensTwoTone';
import { Box, Button, Card, CardMedia, Grid2 as Grid, Typography, Stack, CardContent, IconButton, CardActionArea, CardHeader, CardActions } from '@mui/material';
import { useOpenCv } from 'opencv-react';
import React from 'react';
import Tesseract from 'tesseract.js';
import { BlurDetection } from '../../_helpers/openCV/BlurDetection';
import { CalculateBoundingArea } from '../../_helpers/openCV/CalculateBoundingArea';
import { DetectText } from '../../_helpers/openCV/DetectText';
import { GlareDetection } from '../../_helpers/openCV/GlareDetection';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { drawText } from '../../_helpers/tensorflow/landmarkdetection/utils';
import * as documentVerificationServices from '../../_services/documentverification.service';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


const alerts = [
    {
        id: 1,
        message: "Too much blur",
        error: true
    },
    {
        id: 2,
        message: "Too much glare",
        error: true
    },
    {
        id: 3,
        message: "Too much glare and blur",
        error: true
    },
    {
        id: 4,
        message: "All good",
        error: false
    },
    {
        id: 5,
        message: "We did not find an ID on the image.",
        error: true
    }
]


const DocumentCheckCamera = (props) => {
    const {
        activeStep,
        stopFlag,
        imageData,
        setImageData,
        imageText,
        setImageText,
        loadingChildComponent,
        failedCameraAccessChildComponent,
        noAvailableCameraChildComponent,
        gettingCameraPermissionChild,
        loading,
        setLoading,
        continueCallbackFunction,
    } = props;

    const [failedCameraAccess, setFailedCameraAccess] = React.useState(false);
    const [noAvailableCamera, setNoAvailableCamera] = React.useState(false);
    const [gettingCameraPermission, setGettingCameraPermission] = React.useState(true);
    const [imageErrors, setImageErrors] = React.useState([]);

    const [checkingIsValidImage, setCheckingIsValidImage] = React.useState(false);
    const [isValidImage, setIsValidImage] = React.useState(false);

    const canvasRef = React.useRef(null);
    const liveVideoRef = React.useRef(null);
    const intervalRef = React.useRef(null);
    const stream = React.useRef(null);

    const { cv, loaded: openCvLoaded } = useOpenCv();


    const message = React.useRef({ id: 0, message: "Loading..." });
    const [notify, setNotify] = React.useState({ id: 0, message: "Loading..." });

    const setMessage = (id) => {
        const messageFound = alerts.find((a) => a.id === id);
        message.current = messageFound
        setNotify(messageFound);

        return messageFound;
    }

    const handleVideoLoaded = () => {
        const video = liveVideoRef.current;

        if (video && video.videoWidth > 0 && video.videoHeight > 0) {

            // Set canvas dimensions to match video intrinsic size
            const canvas = canvasRef.current;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Clear any existing interval to prevent multiple intervals from stacking
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            intervalRef.current = setInterval(() => {
                try {
                    detect();
                } catch (error) {
                    console.error('Error in detect function:', error);
                    clearInterval(intervalRef.current);
                }
            }, 1000 / 30); // assuming 30fps

        } else {
            console.error('Video dimensions are invalid or video element is not available.');
        }
    };

    const detect = async () => {
        if (!liveVideoRef.current) {
            console.warn("Video element or model not found.");
            return;
        }

        const video = liveVideoRef.current;
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear canvas

        // Validate video dimensions
        if (!video || video.videoWidth === 0 || video.videoHeight === 0) {
            console.error("Video dimensions are not valid.");
            return;
        }


        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        // // Set video element dimensions
        video.width = videoWidth;
        video.height = videoHeight;

        // Initialize OpenCV Mat
        let roi, src;

        try {
            // Capture frame from video
            src = new cv.Mat(videoHeight, videoWidth, cv.CV_8UC4);
            const cap = new cv.VideoCapture(video);
            cap.read(src);

            // Calculate ROI
            const { pt1, pt2 } = CalculateBoundingArea(cv, src, videoWidth, videoHeight, false);

            // Extract ROI
            const roiSize = new cv.Rect(pt1.x, pt1.y, pt2.x - pt1.x, pt2.y - pt1.y);
            roi = src.roi(roiSize);
            // // Perform checks
            const isBlurred = checkBlur(roi);
            const hasGlare = checkGlare(roi);

            // Map conditions to message ID
            const conditionMap = {
                "false-false": 4,
                "true-true": 3,
                "true-false": 1,
                "false-true": 2,
            };
            const conditionKey = `${isBlurred}-${hasGlare}`;
            const id = conditionMap[conditionKey] || 0; // Default to 0 if condition is unexpected

            setMessage(id);

            // if(id === 4){
            //     takePicture()
            // }

            // Draw message on canvas
            requestAnimationFrame(() => {
                drawFrame(ctx, pt1, pt2, video, videoWidth, videoHeight);
                // drawText(ctx, message.current.message, message.current.error);
            });
        } catch (error) {
            console.error("Error in detection:", error);
        } finally {
            // Clean up OpenCV objects
            if (src) src.delete();
            if (roi) roi.delete();
        }
    };

    const drawRoundedRect = (ctx, x, y, width, height, radius) => {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
    };

    const drawFrame = (ctx, pt1, pt2, video, videoWidth, videoHeight) => {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        // Draw video frame
        ctx.drawImage(video, 0, 0, videoWidth, videoHeight, 0, 0, videoWidth, videoHeight);

        // Draw ROI as ID with rounded corners
        ctx.globalAlpha = 0.5;
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
        drawRoundedRect(ctx, pt1.x, pt1.y, pt2.x - pt1.x, pt2.y - pt1.y, 10); // Adjust the radius as needed
        ctx.fill();
        ctx.globalAlpha = 1.0;
    };


    function checkGlare(src) {
        return GlareDetection(cv, src, null, 1000);
    }

    function checkBlur(src) {
        return BlurDetection(cv, src, null, 400);
    }

    async function extractAllText(src) {
        return DetectText(cv, Tesseract, src);
    }

    async function takePicture() {
        setLoading(true);
        setCheckingIsValidImage(true);
        reset();

        stopCamera();

        // Capture the image directly from the video instead of the canvas
        const video = liveVideoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');

        // Draw the video frame onto the temporary canvas
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to a data URL
        const dataUrl = canvas.toDataURL('image/jpeg', 1);
        const contentBase64 = dataUrl.replace('data:image/jpeg;base64,', '').trim();
        setImageData(contentBase64);

        /**
         * Validate if there is an ID or not.
         */
        try {

            /**
             * If there is an error, throw it.
             */
            if (message.current.error) {
                throw message.current.message
            }

            setCheckingIsValidImage(true);
            const processImageData = await documentVerificationServices.detectIDInImage(contentBase64);

            console.log("Processed....", processImageData);
            const { is_id_card_detected, is_front, img_str } = processImageData.data;

            //Set error if the ID is not detected
            if (!is_id_card_detected) {
                setMessage(5);
            }

            /**
             * Write img_str to the canvasRef
             */
            if (!!img_str) {
                console.log("Writting Image to Canvas.")
                const img = new Image();
                img.onload = () => {
                    const ctx = canvasRef.current.getContext('2d');
                    // Clear the canvas before drawing
                    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                    // Set canvas dimensions to match the image if necessary
                    canvasRef.current.width = img.width;
                    canvasRef.current.height = img.height;
                    // Draw the image onto the canvas
                    ctx.drawImage(img, 0, 0);
                };
                // Set the src attribute to trigger the onload event
                img.src = `data:image/jpeg;base64,${img_str}`;
            }

            setIsValidImage(is_id_card_detected);
            setImageData(img_str ?? contentBase64);

        } catch (err) {

            console.error("Error fetching Image Results", err)
            setIsValidImage(false);

        } finally {
            setLoading(false);
            setCheckingIsValidImage(false);
        }

    }

    const getCameraPermission = async () => {
        //get video permissions and then stream the result media stream to the videoSrc variable
        if ('MediaRecorder' in window) {
            setGettingCameraPermission(true);

            const videoConstraints = {
                audio: false,
                video: {
                    facingMode: 'environment',
                    pan: false,
                    tilt: false,
                    zoom: false,
                },
            };

            try {
                const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);

                if (!!videoStream) {
                    //set videostream to live feed player
                    setFailedCameraAccess(false);
                    setNoAvailableCamera(false);
                    stream.current = videoStream;
                    liveVideoRef.current.srcObject = videoStream;

                    // Try to play the video, handle autoplay issues
                    try {
                        await liveVideoRef.current.play();
                    } catch (playError) {
                        console.error("Failed to play video:", playError);
                    }

                    setLoading(false);
                    setGettingCameraPermission(false);
                } else {
                    // Handle unexpected case where videoStream is falsy but no error was thrown
                    console.warn("Video stream is unexpectedly falsy");
                }

            } catch (error) {
                console.error("Error accessing camera:", error);
                setLoading(false);
                setGettingCameraPermission(false);
                setFailedCameraAccess(true);
                setNoAvailableCamera(false);
            }
        } else {
            setLoading(false);
            setGettingCameraPermission(false);
            setFailedCameraAccess(false);
            setNoAvailableCamera(true);
        }
    };

    const stopCamera = () => {
        if (!!stream.current) {
            stream.current.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    };

    const reset = () => {
        clearInterval(intervalRef.current);
        setImageData(null);
        setImageText(null);
        setIsValidImage(false);
        setImageErrors([]);
    };

    React.useEffect(() => {
        if (openCvLoaded && liveVideoRef.current) {
            getCameraPermission();
        }
    }, [openCvLoaded, liveVideoRef.current]);

    React.useEffect(() => {

        setLoading(true);
        return () => {
            reset();
            stopCamera();
            setLoading(true);
            setFailedCameraAccess(false);
            setNoAvailableCamera(false);
            setGettingCameraPermission(false);
        };
    }, []);

    const retry = () => {
        setLoading(true);
        reset();
        stopCamera();
        getCameraPermission();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {(loading || gettingCameraPermission || failedCameraAccess || noAvailableCamera) && (
                <Grid container direction="column" justifyItems={'center'} alignItems="center" spacing={2}>
                    <Grid>
                        {/** Camera Content */}
                        <Card elevation={5} sx={{
                            width: { xs: "90vw", md: "50vw" },
                            height: "480",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <CardContent>
                                {((loading || gettingCameraPermission || failedCameraAccess || noAvailableCamera) &&
                                    (loading ? loadingChildComponent : null)) ||
                                    (gettingCameraPermission ? gettingCameraPermissionChild : null) ||
                                    (noAvailableCamera ? noAvailableCameraChildComponent : null) ||
                                    (failedCameraAccess ? failedCameraAccessChildComponent : null)}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}


            <Card
                elevation={5}
                sx={{
                    display: (!loading && !gettingCameraPermission && !failedCameraAccess && !noAvailableCamera) ? 'block' : 'none'
                }}
            >
                <CardHeader
                    subheader={
                        <Stack direction={"row"} spacing={1}>
                            {notify.error ?
                                <ErrorIcon color="error" fontSize="small" /> : notify.warning
                                    ? <WarningAmberIcon color="warning" fontSize="small" /> :
                                    <CheckCircleOutlineIcon color="success" fontSize="small" />
                            }
                            <Typography>
                                {notify.message}
                            </Typography>
                        </Stack>
                    }
                />
                <CardContent>
                    <Stack
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={1}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                width: { xs: '70vw', md: '40vw' },
                                maxWidth: '640px',
                                height: { xs: 'calc(50vh / 1.3333)', md: 'calc(40vh / 1.3333)' },
                                maxHeight: 'calc(640px / 1.3333)',
                                margin: '0 auto',
                                overflow: "hidden"
                            }}
                        >

                            <Box
                                component={'video'}
                                id="documentVideo"
                                ref={liveVideoRef}
                                onLoadedMetadata={handleVideoLoaded}
                                autoPlay
                                playsInline
                                muted
                                hidden={!!imageData}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    display: !!imageData ? 'none' : 'block'
                                }}
                            />

                            <Box
                                id={'documentRoi'}
                                component={'canvas'}
                                ref={canvasRef}
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    pointerEvents: 'none', // Allows clicks through to video
                                }}
                            />

                        </Box>

                        {/* <Box sx={{ width: '100%', maxWidth: '100%' }}> */}
                        {/* </Box> */}


                    </Stack>

                </CardContent>

                <CardActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingY: 2,
                    }}
                >
                    {!imageData && !loading && !gettingCameraPermission && !failedCameraAccess && !noAvailableCamera ? (
                        <Stack direction="row" alignItems="center" sx={{ width: '100%', justifyContent: 'center' }}>
                            <IconButton onClick={takePicture}>
                                <LensTwoToneIcon sx={{ fontSize: '3rem !important' }} />
                            </IconButton>
                        </Stack>
                    ) : !!imageData && (
                        <Stack direction="column" spacing={2} alignItems="center" width="100%">
                            {checkingIsValidImage ? (
                                <Typography variant="h4" align="center">
                                    Validating image...
                                </Typography>
                            ) : !isValidImage ? (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={retry}
                                    sx={{ width: '50%', paddingX: 4 }}
                                >
                                    Take Again
                                </Button>
                            ) : (
                                <Stack direction="row" spacing={2} justifyContent="center" width="100%">
                                    <Button
                                        variant="outlined"
                                        color="success"
                                        onClick={() => {
                                            continueCallbackFunction();
                                            if (!stopFlag) {
                                                retry();
                                            }
                                        }}
                                        sx={{ width: '45%', paddingX: 4 }}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="warning"
                                        onClick={retry}
                                        sx={{ width: '45%', paddingX: 4 }}
                                    >
                                        Take Again
                                    </Button>
                                </Stack>
                            )}
                        </Stack>
                    )}
                </CardActions>
                
            </Card>
        </Box>
    );
};

export default DocumentCheckCamera;
